#Actividades2 {
    background-color: white;

    height: 1300px;

    position: relative;

    z-index: 999;

    padding-top: 10px;

    overflow: hidden;
}

#Actividades2 h1 {
    width: 100%;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);

    text-align: center;

    font-size: 3rem;

    position: relative;
    z-index: 55;
}

#Actividades2 .container {
    width: 100%;
    display: flex;
}

#Actividades2 .container .izquierda,
#Actividades2 .container .derecha {
    width: 50%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-end;
}

#Actividades2 .container .derecha {
    align-content: flex-start;
}

#Actividades2 .container .izquierda .actividad {
    text-align: right;
}

#Actividades2 .container .derecha .actividad {
    text-align: left;
    flex-direction: row-reverse;
}

#Actividades2 .actividad {
    width: 90%;
    margin-bottom: 30px;
    display: flex;

    z-index: 2;
}

#Actividades2 .actividad .texto {
    width: 50%;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

#Actividades2 .actividad .texto {
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 20px;
    margin-left: 20px;
}


#Actividades2 .actividad .imagen {
    height: 200px;
    width: 200px;

    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;
}

#Actividades2 .actividad .imagen img {
    height: 90%;
    width: 90%;
}



#Actividades2 .fondo {
    position: relative;
    height: 1700px;

    top: -1700px;
    left: 250px;

    z-index: 1;
}

@media (max-width: 1600px) {
    #Actividades2 {
        height: 1350px;
    }
}

@media (max-width: 1400px) {
    #Actividades2 {
        height: 1500px;
    }

    #Actividades2 .fondo {
        position: relative;
        height: 1800px;

        top: -1600px;
        left: 0px;

        z-index: 1;
    }
}

@media (max-width: 500px) {
    #Actividades2 {
        /* height: 535vh; */
        height: fit-content;
    }
    #Actividades2 .fondo {
        display: none;
    }

    #Actividades2 .container {
        flex-direction: column;
    }

    #Actividades2 .container .izquierda,
    #Actividades2 .container .derecha {
        width: 100%;
    }

    #Actividades2 .actividad {
        width: 100%;
        flex-direction: column-reverse;
        align-items: center;
    }

    #Actividades2 .actividad .texto {
        width: 80%;
        margin: 0;
        margin-top: 20px;
        text-align: center;
    }

    #Actividades2 .container .derecha .actividad {
        flex-direction: column-reverse;
    }
}