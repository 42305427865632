#Cronograma {
    background-color: var(--Oscuro);
    color: white;
}

#Cronograma .fondo {
    width: 0;
    height: 0;
    border-left: 17vw solid transparent;
    border-right: 40vw solid transparent;

    border-top: 100px solid var(--Claro);

    position: relative;
    bottom: 9%;

    z-index: 1;
}

#Cronograma .fondo.abajo {
    border-top: 100px solid var(--Oscuro);

    background-color: var(--Claro);
}

#Cronograma span {
    display: block;
    position: relative;

    width: 100%;

    text-align: center;
    font-size: 1.5rem;

    z-index: 999999999;
}

#Cronograma .cronograma_box {
    position: relative;
    margin-top: 50px;
    z-index: 5;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.MuiButtonBase-root.MuiTab-root {
    font-size: 1.5rem;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorSecondary.Mui-selected.css-1pweck2-MuiButtonBase-root-MuiTab-root {
    color: var(--Acento);
}

.MuiTabs-indicator.css-11yukd5-MuiTabs-indicator {
    background-color: var(--Acento);
}

.MuiTabs-indicator.css-axpu1l {
    background-color: var(--Acento);
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorSecondary.MuiTab-fullWidth.Mui-selected.css-180lpz9 {
    color: var(--Acento);
}

.MuiTouchRipple-root.css-w0pj6f {
    color: var(--Acento);
}

.css-180lpz9.Mui-selected {
    color: var(--Acento);
}

.franja_horaria {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    margin-bottom: 30px;
    /* font-size: 1.5rem; */

    font-size: smaller !important;
    text-align: center;
}

.franja_horaria .eventos .evento {
    padding: 30px;
}

.franja_horaria .hora,
.franja_horaria .eventos .evento {
    /* height: 45px; */
    height: fit-content;

    padding-top: 15px;
    padding-bottom: 15px;

    border-radius: 50px;
    border-style: solid;
    border-width: 1px;
    border-color: var(--Intermedio);

    display: flex;
    align-items: center;
    justify-content: center;
}

.franja_horaria .resaltado {
    border-color: var(--Acento);
    color: var(--Acento);
}

.franja_horaria .hora {
    width: 15%;
}

.franja_horaria .eventos {
    width: 85%;

    display: flex;
    justify-content: space-evenly;
}

.franja_horaria .eventos .evento {
    width: 100%;

    margin-left: 10px;
    margin-right: 10px;
}

@media (max-width: 1400px) {

    .franja_horaria .hora,
    .franja_horaria .eventos .evento {
        height: 30px;
    }

    .franja_horaria {
        margin-bottom: 20px;
        font-size: 1.2rem;
    }
}

@media (max-width: 500px) {
    #Cronograma .fondo {
        border-top: 50px solid var(--Claro);
    }

    .MuiBox-root.css-19kzrtu {
        padding: 10px;
        padding-top: 25px;
    }

    .franja_horaria .hora {
        width: 80px;
    }

    .franja_horaria .hora,
    .franja_horaria .eventos .evento {
        border-radius: 25px;
    }

    .franja_horaria {
        font-size: 0.7rem;
        margin-bottom: 10px;

        text-align: center;
    }


    .franja_horaria .eventos .evento {
        width: 100%;

        margin-left: 5px;
        margin-right: 5px;
    }

    .MuiButtonBase-root.MuiTab-root {
        font-size: 1rem;
    }

    .franja_horaria .hora,
    .franja_horaria .eventos .evento {
        height: 50px;
    }

    #Cronograma {
        display: none;
    }
}