#PreCongreso{
    background-color: var(--Oscuro);

    display: flex;
    flex-direction: column;
    align-items: center;
}
#PreCongreso h1{
    color: white;

    margin-bottom: 50px;
}

#PreCongreso .links{
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
}

#PreCongreso .links a {
    height: 50px;
    width: 70%;

    margin-bottom: 30px;

    background-color: var(--Acento);
    color: white;
    border-radius: 50px;

    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 600;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.7s;
}

#PreCongreso .links a:hover {
    color: var(--Oscuro);
    transition: all 0.7s;
}


@media (max-width: 500px) {
    #PreCongreso h1{
        color: white;

        font-size: 1.5rem;
    
        text-align: center;
    }
    
    #PreCongreso .links a {
        font-size: 1rem;
        text-align: center;
    }
}