html{
  scroll-behavior: smooth;

  background-color: var(--Claro);
}

.app{
  overflow: hidden;
}

:root{
  /* colores biejos */
  --Celeste: #78CDD7;
  --VerdeMedio: #44A1A0;
  --VerdeOscuro: #0D5C63;

  /* colores nuevos */
  --Oscuro: #035C67;
  --Intermedio_1: #218E83;
  --Intermedio_2: #479896;
  --Claro: #74C3D5;
  --Acento: #FFBB4E;
}

::-webkit-scrollbar-track
{
	border-radius: 10px;
}

::-webkit-scrollbar
{
	width: 8px;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: var(--Oscuro);
}

h1{
  font-size: 2rem;
}

h1, h2, h3, h4, h5, h6{
  color: var(--Oscuro);
}