#Simpocio {
    background-color: white;

    padding: 0;
    margin: 0;

    position: relative;
    top: -20px;

    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
}

#Simpocio .fondo {
    position: absolute;
    top: -300px;

    transform: rotate(45deg);

    z-index: 1;
}

#Simpocio .texto {
    width: 80%;

    margin-top: 50px;

    z-index: 2;
}

#Simpocio .texto h2 {
    font-size: 2rem;
}

#Simpocio .imgs {
    width: 80%;

    margin-top: 50px;
    padding-bottom: 50px;

    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;

    z-index: 2;
}

#Simpocio .imgs div {
    height: 300px;
    width: 300px;


    border-radius: 100%;

    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba(255, 255, 255, 0.2);
    /* border-radius: 16px; */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

#Simpocio .imgs div img {
    height: 300px;
    width: 300px;


    border-radius: 100%;

    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 500px) {
    #Simpocio .imgs {
        flex-direction: column-reverse;
    }
}