#Inscribirse {
    position: relative;

    width: 100%;
    height: 100px;

    color: white;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    z-index: 200;
}

#Inscribirse.oscuro {
    background-color: var(--Oscuro);
}

#Inscribirse.claro {
    background-color: var(--Claro);
}

#Inscribirse.blanco {
    background-color: white;
}

#Inscribirse.blanco h2 {
    color: var(--Oscuro);
}

#Inscribirse h2 {
    color: white;
}

#Inscribirse a {
    height: 50px;
    width: 300px;

    background-color: var(--Acento);
    color: white;
    border-radius: 50px;

    text-decoration: none;
    font-size: 2rem;
    font-weight: 600;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.7s;
}

#Inscribirse a:hover {
    color: var(--Oscuro);
    transition: all 0.7s;
}


@media (max-width: 500px) {
    #Inscribirse h2 {
        font-size: 1rem;

        width: 50%;
    }

    #Inscribirse a {
        font-size: 1.3rem;
        width: 200px;

        text-align: center;
    }

}