#header {
    height: 100vh;
    width: auto;

    background-color: var(--Oscuro);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

#header .fondo {
    position: absolute;

    left: 0%;
    top: -25%;

    height: 1500px;
    width: auto;

    transform: rotate(-30deg);
}

#header .logo {
    height: 300px;
    width: auto;

    margin-bottom: 10%;

    z-index: 1;
}

#header .content {
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    overflow: hidden;
}

#header .content .carrucel {
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    overflow: hidden;
}

#header .content button {
    height: 50%;
    width: 15%;

    z-index: 99;

    background-color: transparent;
    border: none;

    cursor: pointer;
}

#header .content .card {
    overflow: hidden;

    display: flex;
    justify-content: center;
}

#header .content .card .afiliacion {
    position: relative;
    /* bottom: 121px; */
    bottom: 31%;
    /* right: -607px; */
    left: 65%;
}

#header .content .card a {
    height: 50px;
    /* width: 300px; */
    width: 30%;

    background-color: var(--Acento);
    color: white;

    border-radius: 50px;

    text-decoration: none;
    font-size: 2rem;
    font-weight: 600;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.7s;
}

#header .content .card a:hover {
    color: var(--Oscuro);
    transition: all 0.7s;
}

#header .content .card.derecha {
    position: absolute;
    left: 90%;
    height: 40%;
    width: 10%;

    transition: all 1s;

    justify-content: left;
}

#header .content .card.izquierda {
    position: absolute;
    left: -40%;
    height: 40%;
    width: 50%;

    transition: all 1s;
}

#header .content .card.centro {
    position: absolute;
    left: 17%;

    height: 50%;
    width: 65%;

    transition: all 1s;
}

#uno {
    z-index: 8;
}

#dos {
    z-index: 7;
}

#tres {
    z-index: 6;
    flex-direction: column-reverse;
    justify-content: center;
}

#cuatro {
    z-index: 5;
}

#cinco {
    z-index: 4;
}

#seis{
    z-index: 3;
}

#siete{
    z-index: 2;
}
#ocho{
    z-index: 1;
}

#tres.derecha img {
    height: 100%;
    width: 700px;
}

#tres.derecha .afiliacion {
    display: none !important;
}

#tres.centro .afiliacion {
    display: flex;

    z-index: 99;
}

#tres.izquierda .afiliacion {
    display: none !important;
}

#uno.izquierda {
    z-index: 1;
}

#dos.izquierda {
    z-index: 2;
}

#tres.izquierda {
    z-index: 3;
}

#cuatro.izquierda {
    z-index: 4;
}

#cinco.izquierda {
    z-index: 5;
}

#seis.izquierda{
    z-index: 6;
}

#siete.izquierda{
    z-index: 7;
}
#ocho.izquierda{
    z-index: 8;
}

#header .content .card.centro img {
    height: auto;
    width: 100%;
}

#header .logos_container {
    height: 100px;
    width: 100%;

    background-color: white;

    display: flex;
    justify-content: center;

    z-index: 2;
}

#header .logos_container .ampros {
    height: 120px;
    width: 120px;

    position: relative;
    top: -60px;
}

#header .logos_container .ampros img {
    height: 100%;
    width: auto;
}

#header .logos_container .logos {
    height: 100%;
    width: 50%;

    display: flex;
    align-items: center;
    justify-content: space-around;

}

#header .logos_container .logos img {
    height: 80%;
    width: auto;

    background-color: white;
}

@media (max-width: 1600px) {
    #header .content .card.centro {
        height: 40%;
        width: 65%;
    }

    #header .content .card.izquierda {
        height: 30%;
        width: 60%;
    }
    #header .content #tres.izquierda {
        height: 30%;
        width: 49%;
    }

    #header .content .card.derecha {
        height: 30%;
    }

    #header .content .card .afiliacion {
        bottom: 27%;
    }
    #tres img {
        position: relative;
        top: 10%;
    }
}

@media (max-width: 1400px) {
    #header .fondo {
        position: absolute;

        left: 0%;
        top: -25%;

        height: 1000px;
        width: auto;

        transform: rotate(-30deg);
    }

    #header .logo {
        height: 200px;

        margin-bottom: 0%;
    }
}
@media (max-width: 1200px) {
    #header .content .card .afiliacion {
        bottom: 12%;
        height: 35px;
    }
}

@media (max-width: 500px) {
    #header {
        height: 95vh;
    }

    #header .logo {
        height: 130px;
    }

    #header .fondo {
        position: absolute;

        left: -55%;
        top: 1%;

        height: 800px;
        width: auto;

        transform: rotate(-5deg);
    }

    #header .content .card.centro {
        height: 60%;
        width: 70%;

        left: 15%;
    }

    #header .content .card.derecha {
        height: 50%;
    }

    #header .content .card.izquierda {
        height: 50%;
        width: 49%;
    }

    #header .logos_container {
        height: 100px;
        width: 100%;
    }

    #header .logos_container .ampros {
        height: 100px;
        width: 100px;

        position: relative;
        top: -60px;
    }

    #header .logos_container .logos.derecha {
        position: relative;
        right: 40px;
    }

    #header .logos_container .logos.izquierda {
        position: relative;
        right: -40px;
    }

    #header .logos_container .logos img {
        height: 35%;
        width: auto;

        background-color: white;

        margin-left: 5px;
        margin-right: 5px;
    }
    #header .content .card .afiliacion {
        bottom: 16%;
        left: 0%;
    }
    #header .content .card a {
        width: 100%;
    
        background-color: transparent;
        color: transparent;
    }
    #header .content #tres.izquierda {
        height: 50%;
        width: 49%;
    }
    #tres img {
        position: relative;
        top: 0%;

        height: 100%;
    }
}


@media (max-width: 400px) {
    #header .fondo{
        display: none;
    }
    #header .logos_container .ampros img {
        height: 80%;
        width: auto;

        position: relative;

        z-index: 10;
    }
    #header .logos_container .logos {
        flex-wrap: wrap;
    }
}