  .dropdown {
      position: relative;
      display: inline-block;
      transition: all 1s;
  }

  .dropdown-content {
      /* height: 0; */
      display: none;
      position: absolute;
      /* top: 30px; */
      background-color: #f1f1f1;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;

      transition: all 1s;
  }

  .dropdown-content a {
      color: #000000;
      padding: 12px 16px;
      text-decoration: none;
      display: block;

      font-size: 1.1rem;

      transition: all 1s;
  }

  /* .dropdown-content a:hover {background-color: #ddd;} */

  .dropdown:hover .dropdown-content {
      display: block;
      /* height: 200px; */

      transition: all 1s;
  }
  /* .dropdown:hover .dropdown-content a{
      display: block;
      color: black;

      transition-delay: 3s;
      transition: all 1s;
  } */

  /* .dropdown:hover .dropbtn {background-color: #3e8e41;} */