#Entrada {
    height: 110vh;

    padding-top: 100px;

    background-color: var(--Oscuro);

    display: flex;
    justify-content: space-evenly;
    flex-direction: column;

    overflow: hidden;
}

#Entrada h1 {
    width: 100%;

    text-align: center;

    color: white;
}

#Entrada .entrada {
    width: 60%;
    height: 35%;

    margin-bottom: 30px;

    background-color: var(--Intermedio_1);
    color: white;

    display: flex;
}

#Entrada .entrada.izquierda {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
}

#Entrada .entrada.derecha {
    margin-left: 40%;

    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;

    flex-direction: row-reverse;
}

#Entrada .entrada .texto {
    height: 100%;
    width: 60%;

    display: flex;
    align-items: center;
}

#Entrada .entrada .texto h2 {
    width: 100%;

    text-align: center;
    font-size: 2rem;

    color: white;
}

#Entrada .entrada .texto .items {
    display: flex;

    margin-left: 20px;
    margin-right: 20px;
}

#Entrada .entrada .texto .items li,
#Entrada .info .container li {
    text-align: left;

    color: var(--Acento);
}

#Entrada .entrada .texto .items li span,
#Entrada .info .container li span {
    color: white;
}

#Entrada .entrada .texto ul {
    width: 100%;

    text-align: center;
    font-size: 1.3rem;
}

#Entrada .info .container ul {
    width: 100%;
    font-size: 1.3rem;
}

#Entrada .info .container img {
    height: 50px;
    width: 50px;
}

#Entrada .entrada .precio {
    height: 100%;
    width: 30%;

    display: flex;
    align-items: center;
    justify-content: center;
}

#Entrada .entrada .precio h3 {
    font-size: 3.5rem;

    color: var(--Acento);
}

#Entrada .aclaraciones {
    width: 100%;

    display: flex;
    justify-content: center;
}

#Entrada .aclaraciones p {
    width: 80%;
    height: 50px;

    background-color: var(--Intermedio_1);
    color: white;
    border-radius: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
}

#Entrada .fondo {
    transform: rotate(50deg);

    position: relative;
    top: -1400px;
}

#Entrada .info {
    width: 100%;

    display: flex;
    justify-content: center;

    margin-bottom: 30px;
}

#Entrada .info .container {
    width: 70%;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);

    display: flex;
    justify-content: space-around;
}

#Entrada .NuevasEntradas {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

#Entrada .NuevasEntradas .container {
    width: 80%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

#Entrada .NuevasEntradas .container .fila {
    width: 100%;

    margin-bottom: 30px;

    display: flex;
    justify-content: space-evenly;
}

#Entrada .NuevasEntradas .container .fila .nombre,
#Entrada .NuevasEntradas .container .fila .precio {
    color: black;

    font-weight: bold;
    text-align: center;
    font-size: 1.5rem;

    height: 60px;

    padding-left: 30px;
    padding-right: 30px;

    border-radius: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#Entrada .NuevasEntradas .container .fila .nombre small,
#Entrada .NuevasEntradas .container .fila .precio small {
    font-size: 0.8rem;
}

#Entrada .NuevasEntradas .container .fila .nombre {
    background-color: var(--Claro);

    width: 200px;
}

#Entrada .NuevasEntradas .container .fila .nombre.destacado {
    background-color: var(--Intermedio_1);
}

#Entrada .NuevasEntradas .container .fila .precio {
    background-color: var(--Acento);

    width: 100px;
}

@media (max-width: 1400px) {
    #Entrada .entrada {
        height: 40%;
    }

    #Entrada .entrada .texto ul {
        font-size: 1rem;
    }

    #Entrada {
        height: 130vh;

        padding-top: 0px;
    }
}

@media (max-width: 500px) {
    #Entrada {
        /* height: 100vh; */
        height: fit-content;
    }

    #Entrada .entrada {
        width: 80%;
        height: fit-content;

        flex-direction: column;

        margin-bottom: 0px;
    }

    #Entrada .info .container {
        width: 90%;
    }

    #Entrada .info .container ul {
        font-size: 1rem;
    }

    #Entrada .entrada.derecha {
        margin-left: 20%;
        flex-direction: column;
    }

    #Entrada .entrada .texto h2 {
        width: 100%;

        text-align: center;
        font-size: 1.5rem;
    }

    #Entrada .entrada .texto {
        height: 100%;
        width: 100%;
    }

    #Entrada .entrada .precio {
        height: 100%;
        width: 100%;
    }

    #Entrada .entrada .precio h3 {
        font-size: 2.5rem;
        margin: 0;
    }

    #Entrada .entrada .texto ul {
        font-size: 1.1rem;
    }

    #Entrada .entrada .texto li {
        margin-bottom: 5px;
    }

    #Entrada .NuevasEntradas {
        width: 100%;
    
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #Entrada .NuevasEntradas .container{
        margin-bottom: 50px;
    }
}