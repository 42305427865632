nav {
    height: 60px;
    width: 100%;

    position: fixed;
    z-index: 10000;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    --ColorFondo: var(--Oscuro);
    /* background-color: var(--Intermedio_2); */
    /* background-color: var(--Oscuro); */
    background-color: white;
}

nav .logo_container {
    height: 100%;
    width: 300px;

    display: flex;
    align-items: center;
    justify-content: center;
}

nav .logo_container img {
    height: 70%;
    width: auto;

    margin-right: 20px;
}

nav .logo_container .st0 {
    display: none;
    fill: #1B998B;
}

nav .logo_container .st1 {
    fill: var(--Intermedio);
    transition: all 0.4s;
}

nav .logo_container svg {
    height: 80%;
    width: 100%;
}

nav .logo_container svg:hover .st1 {
    fill: white;
}

nav .nav_sections {
    height: 100%;
    width: 60%;

    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;

    display: flex;
    align-items: center;
    justify-content: center;
}

nav a,
nav span {
    margin-left: 20px;
    margin-right: 20px;

    color: var(--Oscuro);

    text-decoration: none;
    text-align: center;
    font-size: 1.2rem;

    cursor: pointer;

    transition: all 0.4s;
    display: flex;
}

nav a:hover,
nav span:hover {
    color: var(--Acento);
    /* font-size: 1.7rem; */
}

nav a.logo_container {
    margin-left: 0;
    margin-right: 0;
}


nav .CallToAccion_container {
    height: 100%;
    width: 300px;

    border-bottom-left-radius: 100px;

    display: flex;
    align-items: center;
    justify-content: center;
}

nav .CallToAccion_container .CallToAccion {
    height: 60%;
    width: 200px;

    border-radius: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--Acento);

    text-decoration: none;
    font-size: 1.9rem;
    font-weight: 600;

    transition: all 0.4s;
}

nav .CallToAccion_container .CallToAccion:hover {
    color: var(--Oscuro);
}

@media (max-width: 1400px) {

    nav a,
    nav span {
        font-size: 0.7rem;
    }
}

@media (max-width: 500px) {
    nav {
        background: rgba(255, 255, 255, 0.2);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
    }

    nav .logo_container,
    nav .CallToAccion_container {
        background: none;
        box-shadow: none;
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
        border: none;
    }

    nav .nav_sections {
        display: none;
    }
}