#disertantes {
    position: relative;

    background-color: var(--Claro);

    width: 100%;
    /* height: 300vh; */
    height: fit-content;

    z-index: 20;

    overflow: hidden;
}

#disertantes .container {
    position: relative;

    width: 100%;
    
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    z-index: 5;
}

#disertantes .container h1.title{
    width: 100%;

    margin-left: 5%;
    margin-right: 5%;
    margin-top: 0;
    margin-bottom: 5%;

    padding-left: 10%;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 2rem;

    background-color: white;
    background-color: var(--Oscuro);
    color: var(--Acento);

    border-radius: 50px;
}
#disertantes .container h1.title.secondary{
    margin-top: 5%;
}

#disertantes .disertante, #disertantes ._3topic {
    width: 40%;

    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 50px;

    padding: 10px;

    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    justify-content: flex-start;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);

    cursor: pointer;

    text-decoration: none;
}

#disertantes ._3topic{
    width: 92%;

    font-size: 1.5rem;
    text-align: left;
}
#disertantes ._3topic p{
    padding-left: 40px;
    padding-right: 40px;
}
#disertantes ._3topic.ejes{
    width: 92%;

    font-size: 1.3rem;
    text-align: left;

    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: var(--Oscuro);
}
#disertantes ._3topic.ejes li{
   color: var(--Acento);
}
#disertantes ._3topic.ejes li span{
   color: white;
}

#disertantes .disertante .texto ul {
    padding-right: 30px;
}

#disertantes .disertante .texto h2 {
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;

    font-size: 2rem;
}

#disertantes .disertante .texto h4 {
    margin-bottom: 0;
    margin-top: 0;
}

#disertantes .disertante .texto small {
    margin: 0;

    color: black;
}

#disertantes .disertante .texto ul li {
   color: var(--Acento);
}
#disertantes .disertante .texto ul li span{
   color: black;
}

#disertantes .disertante .foto {
    display: flex;
    align-items: center;
    justify-content: center;
}

#disertantes .disertante .foto img {
    height: 150px;
    width: 150px;

    border-radius: 100px;
}


#disertantes ._3_topic .disertante {
    width: 10%;

    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 50px;

    padding: 10px;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px); 
}

#disertantes ._3_topic .disertante .texto h2 {
    font-size: 1rem;
}

#disertantes ._3_topic .disertante .foto{
    display: none;
}

#disertantes ._3_topic .disertante .texto{
    margin: 0;
}

#disertantes ._3_topic .disertante .texto h2 {
    text-align: center;
    margin: 0;
    width: 100%;
}

#disertantes ._3_topic .disertante .texto small {
    display: none;
}

#disertantes .fondo {
    position: relative;
    top: -2700px;
    left: -30%;

    height: 3000px;
    width: 3000px;

    transform: rotate(20deg);

    z-index: 1;

    display: none;
}
#disertantes .newfondo {
    position: absolute;
    top: -500px;
    left: -40%;

    height: 2000px;
    width: 2000px;

    transform: rotate(60deg);

    z-index: 1;
}
#disertantes .newfondo2 {
    position: absolute;
    top: 1500px;
    left: -10%;

    height: 2000px;
    width: 2000px;

    transform: rotate(80deg);

    z-index: 1;
}

@media (max-width: 1600px) {
    /* #disertantes {
        height: 330vh;
    } */
    #disertantes .fondo {
        position: relative;
        top: -3200px;
        left: -60%;

        height: 3200px;
        width: 3200px;
    }
}

@media (max-width: 1400px) {
    /* #disertantes {
        height: 530vh;
    } */
}


@media (max-width: 500px) {
    #disertantes {
        /* height: 1014vh; */
        height: fit-content;
    }
    
    #disertantes .disertante {
        width: 85%;
        margin-left: 0px;
        margin-right: 0px;
        flex-direction: column;
    }

    #disertantes ._3topic {
        font-size: 1rem;
    }
    #disertantes ._3topic.ejes {
        font-size: 1rem;
    }

    #disertantes .fondo,  #disertantes .newfondo,  #disertantes .newfondo2 {
        display: none;
    }

}