#Salon {
    height: 100vh;

    overflow: hidden;

    background-color: var(--Oscuro);
}

#Salon .texto {
    height: 20%;
    width: 50%;

    padding-left: 50px;
    padding-top: 5px;
    padding-bottom: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;
    top: 10%;

    z-index: 2;

    color: white;
    text-decoration: none;

    background-color: var(--Intermedio_1);
}

#Salon .texto h1 {
    color: white;
}


#Salon .mainImg {
    height: 500px;
    width: 700px;

    transform: rotate(-30deg);

    position: relative;
    top: -40%;
    left: 70%;
    z-index: 2;

    border-radius: 50px;

    overflow: hidden;
}

#Salon .mainImg img {

    width: 800px;

    position: relative;
    left: -35%;
    top: -0%;

    transform: rotate(30deg);
}


#Ubicacion {
    height: 109%;

    position: relative;
    top: -80%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

#Ubicacion .container {
    height: 100%;
    width: 100%;

    padding-top: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
}


@media (max-width: 500px) {
    #Salon .mainImg {
        display: none;
    }

    #Ubicacion {
        height: 80%;
        width: 100%;

        position: inherit;
    }

    #Salon .texto {
        width: 100%;

        top: 0%;

        padding-left: 0px;

        text-align: center;

        color: var(--Oscuro);
        background-color: var(--Claro);
    }

    #Salon .texto h1 {
        color: var(--Oscuro);
        font-size: 1.5rem;
        text-align: center;
    }


}