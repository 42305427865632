#Hoteles {
    background-color: var(--Oscuro);

    padding-top: 50px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

#Hoteles h1 {
    width: 100%;
    text-align: center;
    color: white;
}

#Hoteles .tableContainer {
    width: 80%;

    display: flex;
    /* justify-content: space-around; */
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}

.table-wrapper {
    /* margin: 10px 70px 70px; */
    box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.2);

    width: 85%;

    margin-top: 50px;

    border-radius: 16px;

    display: flex;
    justify-content: center;
}

.table-wrapper.doble {
    width: 40%;
}

.table-wrapper.triple {
    width: 25%;
}

.fl-table {
    font-size: 1rem;
    font-weight: normal;
    border: none;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    /* white-space: nowrap; */
    background-color: white;

    border-radius: 16px;

    overflow: hidden;
}

.fl-table td,
.fl-table th {
    text-align: center;
    padding: 8px;
}

.fl-table td {
    border-right: 1px solid #f8f8f8;
    font-size: 12px;
}

.fl-table thead {
    max-width: 100%;
}

.fl-table tbody {
    max-width: 100%;
}

.fl-table thead th {
    color: #ffffff;
    background: var(--Intermedio_1);
}

.fl-table thead th:nth-child(odd) {
    color: #ffffff;
    /* background: #324960; */
    background: var(--Intermedio_2);
}

.fl-table tr:nth-child(even) {
    background: #F8F8F8;
}

.fl-table .title {
    /* color: var(--Acento) !important; */
    color: var(--Oscuro) !important;
    /* background: var(--Oscuro) !important; */
    background: white !important;
}



@media (max-width: 500px) {
    #Hoteles .tableContainer {
        flex-flow: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .table-wrapper {
        width: 100% !important;
    }

    #Hoteles h1 {
        width: 80%;
    }
}