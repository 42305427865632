#Actividades {
    padding-top: 70px;
    height: 100vh;


    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: var(--Oscuro);
}

#Actividades .fondo {
    position: absolute;
    top: 6200px;

    height: 2100px;
    left: -200px;

    transform: rotate(30deg);
}

#Actividades h1 {
    color: white;
    z-index: 5;
}

#Actividades h2 {
    color: var(--Acento);
}

#Actividades .flag_container {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: space-around;

    z-index: 5;
}

#Actividades .flag {
    width: 450px;
    height: 800px;

    background-color: var(--Intermedio_2);
    color: white;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;

    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
}

#Actividades .flag p {
    height: 50%;
    margin: 30px;
    margin-top: 0;

    font-size: 1.3rem;
}

#Actividades .flag svg {
    width: 30%;
    height: auto;
}

@media (max-width: 1600px) {
    #Actividades .fondo {
        position: absolute;
        top: 6850px;

        height: 1800px;
        left: -250px;

        transform: rotate(30deg);
    }
}

@media (max-width: 1400px) {
    #Actividades .fondo {
        position: absolute;
        top: 2100px;
        /* left: 700px; */

        height: 1600px;
        left: -200px;

        transform: rotate(30deg);
    }

    #Actividades h2 {
        margin-top: 30px;
    }

    #Actividades .flag p {
        margin-top: -20%;
        font-size: 0.95rem;
    }

    #Actividades .flag {
        width: 350px;
        height: 500px;

        justify-content: space-between;

        border-bottom-right-radius: 50px;
        border-top-right-radius: 50px;
    }

    #Actividades .flag svg {
        width: 20%;
        height: auto;
    }

}

@media (max-width: 500px) {
    #Actividades {
        height: fit-content;
        /* height: 300vh; */
    }

    #Actividades .fondo {
        display: none;
    }

    #Actividades .flag_container {
        flex-direction: column;
        align-items: center;
    }

    #Actividades .flag_container .flag {
        margin-bottom: 25px;
    }

    #Actividades .flag svg {
        width: 30%;
    }
}