#Tematica {
  position: relative;
  height: 100vh;
  /* width: 90vw; */

  /* padding-top: 20px; */
  padding-left: 50px;
  padding-right: 50px;

  overflow: hidden;
  background-color: var(--Claro);

  z-index: 10;
}

#Tematica h1 {
  /* color: var(--Acento); */
  color: var(--Oscuro);

  font-size: 6rem;

  position: relative;
  z-index: 5;
}

#Tematica .container {
  display: flex;

  position: relative;
  z-index: 5;
}

#Tematica .container p {
  font-size: 1.5rem;
  width: 50%;
}

#Tematica .forma {
  position: relative;
  top: -60%;
  left: 55%;
}

#Tematica img {
  position: relative;
  height: 700px;
  width: auto;

  /* left: -40%;
  top: -350px; */
}

#Tematica .fondo {
  height: auto;
  width: 1200px;

  transform: rotate(40deg);

  position: relative;
  top: -177%;
  /* left: 10%; */

  z-index: 1;
}

@media (max-width: 1600px) {
  #Tematica h1 {
    font-size: 5rem;
  }

  #Tematica .forma {
    position: relative;
    top: -50%;
    left: 50%;
  }
  #Tematica img {
    position: relative;
    height: 600px;
    width: auto;
}
}

@media (max-width: 1400px) {
  #Tematica {
    padding-top: 20px;
  }

  #Tematica h1 {
    font-size: 4rem;
  }

  #Tematica img {
    height: 60vh;
    width: auto;
  }

  #Tematica .container p {
    font-size: 1rem;
  }

  #Tematica .forma {
    top: -55%;
    left: 65%;

    z-index: 55;
  }
}

@media (max-width: 500px) {
  #Tematica {
    height: fit-content;
  }

  #Tematica h1 {
    font-size: 2rem;
  }

  #Tematica img {
    display: none;
  }

  #Tematica .container p {
    width: 100%;
  }
}