#Autoridades {
    position: relative;

    /* height: 130vh; */

    background-color: var(--Claro);

    display: flex;
    flex-direction: column;
    align-items: center;

    overflow: hidden;
    z-index: 10;
}

#Autoridades .comicion_cientifica,
#Autoridades .comicion_directiva {
    position: relative;
    z-index: 11;
}

#Autoridades .fondo {
    position: relative;
    top: -2000px;
    left: 400px;

    height: 3000px;
    width: auto;

    transform: rotate(50deg);
    z-index: 10;
}

#Autoridades h1 {
    margin-top: 70px;
    width: 60%;

    text-align: center;
    font-size: 3rem;

    background-color: var(--Oscuro);
    color: var(--Acento);

    border-radius: 50px;

    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;

    position: relative;
    z-index: 999;
}

#Autoridades .comicion_directiva {
    width: 100%;


    display: flex;
    justify-content: space-evenly;
}

#Autoridades .comicion_directiva img {
    height: 300px;
}

#Autoridades .comicion_directiva .autoridad {
    width: 30vw;

    text-align: center;

    display: flex;
    align-items: center;
    flex-direction: column;
}

#Autoridades .comicion_directiva .autoridad h2 {
    font-size: 2rem;
}

#Autoridades .comicion_directiva .autoridad p strong {
    font-size: 1.3rem;
    color: var(--Oscuro);
}

#Autoridades .comicion_directiva .autoridad p {
    word-break: break-all;

    padding: 20px;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

#Autoridades .comicion_cientifica {
    width: 100%;

    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
}

#Autoridades .comicion_cientifica h2 {
    font-size: 2rem;

    margin-bottom: 50px;

    background-color: var(--Oscuro);
    color: var(--Acento);

    border-radius: 50px;

    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
}

#Autoridades .comicion_cientifica .nombres {

    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
}

#Autoridades .comicion_cientifica .pichi {
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 40px;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);

    padding: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
}

#Autoridades .comicion_cientifica .pichi img {
    height: 200px;
}

@media (max-width: 1600px) {
    /* #Autoridades {
        height: 140vh;
    } */
}


@media (max-width: 1400px) {
    /* #Autoridades {
        height: 200vh;
    } */

    #Autoridades .fondo {
        position: relative;
        top: -2000px;
        left: 200px;

        height: 2000px;
        width: auto;

        transform: rotate(50deg);
        z-index: 10;
    }

    #Autoridades .comicion_directiva img {
        height: 200px;
    }
}


@media (max-width: 500px) {
    #Autoridades {
        /* height: 325vh; */
        height: fit-content;
    }

    #Autoridades .fondo {
        display: none;
    }

    #Autoridades .comicion_directiva {
        flex-direction: column;
        align-items: center;

        text-align: center;
    }

    #Autoridades .comicion_directiva .autoridad {
        width: 90vw;
    }

    #Autoridades .comicion_directiva img {
        height: 150px;
    }

    #Autoridades .comicion_cientifica h2 {
        width: 100%;
        text-align: center;
    }

    #Autoridades .comicion_cientifica .pichi {
        margin: 5px;
        margin-top: 0;
        margin-bottom: 20px;
    }

    #Autoridades .comicion_cientifica .nombres {
        flex-direction: column;
        flex-wrap: nowrap;
    }
}